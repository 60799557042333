const getBaseUrl = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin

    var apiUrl = `${base_url}/haeven/haeven-api/api`
    return apiUrl
  } else {
    return ''
  }
}

const getTemplate = () => {
  if (window?.location?.origin) {
    var base_url = window.location.origin

    var apiUrl = `${base_url}/haeven/haeven-api/template_haeven.xlsx`
    return apiUrl
  } else {
    return ''
  }
}

const appConfig = {
  pathUserTemplate: 'https://limeeasy-api.eventqiu.com/template_limeeasy.xlsx',
  //pathUserTemplate: getTemplate(),
  pathCodeTemplate: '',
  apiPrefix: 'https://haeven-api.eventqiu.com/api',
  //apiPrefix: getBaseUrl(),
  authenticatedEntryPath: '/admin-eventqiu/user',
  unAuthenticatedEntryPath: '/admin-eventqiu/sign-in',
  adminPath: '/admin-eventqiu',
  tourPath: '/',
  notFound: '/not-found',
  locale: 'en',
  enableMock: false,
}

export default appConfig
